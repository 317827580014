import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router";

import * as turf from "@turf/turf";
import {
  postReachDestinationAsync,
  postRouteAnalyses,
  postSearchDataAsync,
} from "../services/services";
import { baseUrl } from "../constants/urls";
import {
  getPlaceConfigAsync,
  getRoutesAsync,
} from "../services/routesServices";
// import i18n from "../plugins/i18n";

Vue.config.devtools = true;

Vue.use(Vuex);

export default new Vuex.Store({
  //////////STATES////////
  state: {
    focusCoordsParamsInfo: null,
    place: null,
    stores: [],
    campaign: null,
    loading: false,
    routerLoader: false,
    noRoutes: false,
    placesLoading: true,
    categories: [],
    coordinates: [],
    styleUrl: null,
    accessToken: null,
    floor: 0,
    center: [],
    pureRoute: [],
    title: "",
    facilitiesTitle: "",
    shopInfo: {
      title: "",
      category: "",
      icon: "",
      properties: {
        categoryId: "",
        image: "",
        phone: "",
        subcategoryId: "",
        text: "",
        webSite: "",
        workingHours: "",
        zone_id: "",
      },
      center: "",
    },
    drawRoute: [],
    Id: null,
    zoom: null,
    routeInfo: [],
    routeFloor: 0,
    floors: [],
    stepper: 0,
    user: null,
    floorShifter: 0,
    defaultUserLocation: null,
    parkingLabels: [],
    parkingSpots: [],
    allData: [],
    navigation: false,
    clustersList: [],
    showClusters: false,
    searchDestination: "",
    destinationDialog: false,
    routeWarning: "",
    postSearchData: {},
    startPointEndPointInfo: {
      startLocation: [],
      endLocation: [],
      startPoint: null,
      endPoint: null,
    },
    urlParamsConfigs: {
      campaign: "",
      navigation: "",
      parking: "",
      topPoiCount: "",
      multiStoreIds: [],
    },
    openFloorsDropDown: false,
    routeType: "",
    mapRef: "",
    placeConfig: null,
  },

  /////////MUTATIONS///////
  mutations: {
    pureRoute(state, payload) {
      state.pureRoute = payload;
    },
    placeConfig(state, payload) {
      state.placeConfig = payload;
    },
    mapRef(state, payload) {
      state.mapRef = payload;
    },
    routeWarning(state, payload) {
      state.routeWarning = payload;
    },
    openFloorsDropDown(state, payload) {
      state.openFloorsDropDown = payload;
    },
    urlParamsConfigs(state, payload) {
      state.urlParamsConfigs = payload;
    },
    clustersList(state, payload) {
      state.clustersList = payload;
    },
    showClusters(state, payload) {
      state.showClusters = payload;
    },
    searchDestination(state, payload) {
      state.searchDestination = payload;
    },
    destinationDialog(state, payload) {
      state.destinationDialog = payload;
    },
    shopInfo(state, payload) {
      state.shopInfo = payload;
    },
    focusCoordsParamsInfo(state, payload) {
      state.focusCoordsParamsInfo = payload;
    },
    facilitiesTitle(state, payload) {
      state.facilitiesTitle = payload;
    },

    // ** this is for routeType of getting rout method
    routeType(state, payload) {
      state.routeType = payload;
    },

    //set all the information about the place
    setPlace(state, payload) {
      state.place = payload;
    },
    setNavigation(state, payload) {
      state.navigation = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    defaultUserLocation(state, payload) {
      state.defaultUserLocation = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setStores(state, payload) {
      state.stores = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
    setCampaign(state, payload) {
      state.campaign = payload;
    },
    setCoordinates(state, payload) {
      state.coordinates = payload;
    },
    setFloor(state, payload) {
      state.floor = payload;
    },
    setFloorShifter(state, payload) {
      state.floorShifter = payload;
    },
    setId(state, payload) {
      state.Id = payload;
    },
    drawRoute(state, payload) {
      state.drawRoute = payload;
    },
    setZoom(state, payload) {
      state.zoom = payload;
    },
    setStyleUrl(state, payload) {
      state.styleUrl = payload;
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    routeInfo(state, payload) {
      state.routeInfo = payload;
    },
    routeFloor(state, payload) {
      state.routeFloor = payload;
    },
    resetRouteFloor(state, payload) {
      state.routeFloor = payload;
    },
    stepper(state, payload) {
      state.stepper = payload;
    },
    resetStepper(state, payload) {
      state.stepper = payload;
    },

    setFloors(state, payload) {
      state.floors.push(payload);
    },
    resetFloors(state) {
      state.floors = [];
    },
    setParkingLabels(state, payload) {
      state.parkingLabels = payload;
    },
    setParkingSpots(state, payload) {
      state.parkingSpots = payload;
    },
    allData(state, payload) {
      state.allData = payload;
    },
    //changes the floor state
    changeFloor(state, payload) {
      // if (payload > 0 && state.floor < state.coordinates.length - 1) {
      //   state.floor = state.floor + payload;
      // }
      // if (payload < 0 && state.floor > 0) {
      //   state.floor = state.floor + payload;
      // }
      state.floor = payload;
    },
    noRoutes(state, payload) {
      state.noRoutes = payload;
    },
    changeFloor2(state, payload) {
      state.floor = payload;
    },
    //sets center of the map
    setCenter(state, payload) {
      state.center = payload;
    },
    //loading state
    loading(state, payload) {
      state.loading = payload;
    },
    placesLoading(state, payload) {
      state.placesLoading = payload;
    },
    postSearchData(state, payload) {
      state.postSearchData = payload;
    },
    startPointEndPointInfo(state, payload) {
      state.startPointEndPointInfo = payload;
    },
    routerLoader(state, payload) {
      state.routerLoader = payload;
    },
  },

  //////ACTIONS///////
  actions: {
    pureRoute({ commit }, payload) {
      commit("pureRoute", payload);
    },
    mapRef({ commit }, payload) {
      commit("mapRef", payload);
    },
    routeType({ commit }, payload) {
      commit("routeType", payload);
    },
    focusCoordsParamsInfo({ commit }, payload) {
      commit("focusCoordsParamsInfo", payload);
    },
    routerLoader({ commit }, payload) {
      commit("routerLoader", payload);
    },
    openFloorsDropDown({ commit }, payload) {
      commit("openFloorsDropDown", payload);
    },
    urlParamsConfigs({ commit }, payload) {
      commit("urlParamsConfigs", payload);
    },
    routeWarning({ commit }, payload) {
      commit("routeWarning", payload);
    },
    clustersList({ commit }, payload) {
      commit("loading", true);
      commit("drawRoute", []);
      commit("startPointEndPointInfo", {});
      commit("routeInfo", []);
      // commit("reset");

      commit("clustersList", payload);

      //change zoom level
      commit(
        "setZoom",
        this.getters.coordinates[this.getters.floor].properties.zoomLevel
      );
      commit("loading", false);
    },
    showClusters({ commit }, payload) {
      commit("loading", true);
      commit("drawRoute", []);
      commit("startPointEndPointInfo", {});
      commit("routeInfo", []);

      // commit("reset");

      commit("showClusters", payload);
      commit("loading", false);
    },
    searchDestination({ commit }, payload) {
      commit("searchDestination", payload);
    },
    destinationDialog({ commit }, payload) {
      commit("destinationDialog", payload);
    },
    shopInfo({ commit }, payload) {
      commit("shopInfo", payload);
    },
    facilitiesTitle({ commit }, payload) {
      commit("facilitiesTitle", payload);
    },
    //Changes loading state and show loading animation
    setLoading({ commit }, payload) {
      commit("loading", payload);
    },
    setPlacesLoading({ commit }, payload) {
      commit("placesLoading", payload);
    },
    setNoRoutes({ commit }, payload) {
      commit("noRoutes", payload);
    },
    //gets all the places from the API with the
    async getPlace({ commit }) {
      commit("loading", true);
      const urlParams = new URLSearchParams(window.location.search);
      const placeConfigLanguageList = this.getters.placeConfig?.languages;
      const defaultLanguage = "tr";
      const fallbackLanguage = "en";

      let language = fallbackLanguage;

      if (placeConfigLanguageList) {
        const languageParams = urlParams.get("language");
        if (
          languageParams &&
          placeConfigLanguageList.includes(languageParams)
        ) {
          language = languageParams;
        } else if (placeConfigLanguageList.includes(defaultLanguage)) {
          language = defaultLanguage;
        }
      } else {
        language = defaultLanguage;
      }

      axios
        .get(
          `${baseUrl}/webn/m/${localStorage.placeId}?language=${language}`,

          {
            headers: {
              "Ocp-Apim-Subscription-Key": "f5a019f020aa4569bae71d1b3eedc3d1",
            },
          }
        )
        .then((response) => {
          let floorArr = [];
          let parkingArr = [];

          response.data.data.map.floors.forEach((e) => {
            if (!e.showOnNavigationMenu) {
              parkingArr.push(e);
            }
            floorArr.push(e);
          });

          let parkingLabels = parkingArr.map((obj) => {
            return {
              title: obj.title,
              level: obj.level,
              labels: obj.properties.parkingLabels,
            };
          });

          commit("setParkingLabels", parkingLabels);

          floorArr.sort((a, b) => {
            if (a.level > b.level) return -1;
            return a.level > b.level ? 1 : 0;
          });

          //set center of the map
          let arrIndex = 0;
          let defaultFloor = 0;

          commit("resetFloors");
          floorArr.forEach((e) => {
            commit("setFloors", e);
            if (e.level == response.data.data.map.properties.defaultFloor) {
              defaultFloor = arrIndex;
            }
            arrIndex++;
          });

          commit("setCenter", floorArr[defaultFloor].properties.centerPoint);
          commit("setFloor", defaultFloor);

          //set zoom level
          commit("setZoom", floorArr[defaultFloor].properties.zoomLevel);

          //store all information about the floors
          commit("setCoordinates", floorArr);
          //set default floor

          //set all the information about the place
          commit("setPlace", response.data);

          commit("setStyleUrl", response.data.data.map.styleUrl);

          commit("setAccessToken", response.data.data.map.accessToken);

          commit("loading", false);

          // add language list to browser storage
        })
        .catch((error) => {
          commit("loading", false);
          console.log(error);
          router.push({ path: "Error" });
        });
    },

    // get place configuration
    async getPlaceConfig({ commit }) {
      commit("loading", true);

      let response = await getPlaceConfigAsync();
      // response = {
      //   ...response,
      //   routeColor: "#F00154",
      //   routeStyle: "dotted",
      //   userMarkerImage:
      //     "https://poigeneral.blob.core.windows.net/navsdk/category-icons/ffd1f1f2-d152-40ef-acb2-1e75a7ce3a52.png",
      //   floorDropdown: {
      //     position: "top",
      //     expandable: true,
      //   },
      //   css: ".v-application .accent { background-color: #FFCC00 !important; border-color: #FFCC00 !important; } .v-application .primary { background-color: #000C42 !important; border-color: #000C42 !important;} .v-application .primary--text {color: #000C42 !important; caret-color: #000C42 !important;} .v-application .secondary { background-color: #F00154 !important; border-color: #F00154 !important;}",
      // };

      if (response) {
        commit("placeConfig", response);
        commit("setNavigation", response.navigation);
        commit("loading", false);

        if (response.floorDropdown && response.floorDropdown.expandable) {
          commit("openFloorsDropDown", response.floorDropdown.expandable);
        } else {
          commit("openFloorsDropDown", false);
        }
      } else {
        commit("placeConfig", response);
        commit("loading", false);
      }
    },
    //gets all the stores from the API with the place id
    async getStores({ commit }) {
      const urlParams = new URLSearchParams(window.location.search);
      const placeConfigLanguageList = this.getters.placeConfig?.languages;
      const defaultLanguage = "tr";
      const fallbackLanguage = "en";

      let language = fallbackLanguage;

      if (placeConfigLanguageList) {
        const languageParams = urlParams.get("language");
        if (
          languageParams &&
          placeConfigLanguageList.includes(languageParams)
        ) {
          language = languageParams;
        } else if (placeConfigLanguageList.includes(defaultLanguage)) {
          language = defaultLanguage;
        }
      } else {
        language = defaultLanguage;
      }

      commit("placesLoading", true);
      //get store information
      var storesData = await axios.get(
        `${baseUrl}/webn/c/` + localStorage.placeId + "?language=" + language,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": "f5a019f020aa4569bae71d1b3eedc3d1",
          },
        }
      );

      //Get all categories from the description, trim and remove duplicates then set
      let allCategories = [];
      let parkingSpots = [];

      storesData.data.forEach((element) => {
        if (element.filters) {
          if (element.filters.pointType) {
            if (element.filters.pointType[0] === "parking") {
              parkingSpots.push(element);
            }
          }
        }

        // let urlParams = new URLSearchParams(window.location.search);
        // const startPoint = urlParams.get("startStoreId");
        // const hasStartPoint = urlParams.has("startStoreId");

        // if (hasStartPoint) {
        //   if (element.filters.store_ids.includes(startPoint)) {
        //     commit("setCenter", element.location.geometry.coordinates);
        //     commit("changeFloor2", element.location.properties.floorLevel);
        //   }
        // }

        if (element.category) {
          if (element.navigation) {
            if (element.navigation.properties.isVisibleOnList) {
              allCategories.push(element.category);
            }
          }
        }
      });
      commit("allData", storesData.data);
      //Sort categories
      // allCategories.sort(function(a, b) {
      //   return a.localeCompare(b, "tr");
      // });

      const sortedAlphabetically = allCategories.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      //Set categories list
      const uniqCategories = Array.from(
        new Set(sortedAlphabetically.map((a) => a.id))
      ).map((id) => {
        return sortedAlphabetically.find((a) => a.id === id);
      });

      // let uniqCategories = Array.from(new Set(allCategories));
      commit("setParkingSpots", parkingSpots);
      commit("setCategories", uniqCategories);

      commit("setStores", storesData.data);

      commit("placesLoading", false);
    },

    //Get campaign data
    async getCampaign({ commit }) {
      let urlParams = new URLSearchParams(window.location.search);

      const hasLanguageParams = urlParams.has("language");
      const languageParams = urlParams.get("language");

      let lang = hasLanguageParams
        ? `&language=${languageParams}`
        : "&language=tr";

      //gets campaing information
      commit("loading", true);

      var campaignData = await axios.get(
        `${baseUrl}/campaigns?placeId=` + localStorage.placeId + lang,

        {
          headers: {
            "Ocp-Apim-Subscription-Key": "f5a019f020aa4569bae71d1b3eedc3d1",
          },
        }
      );
      commit("setCampaign", campaignData.data);
      commit("loading", false);
    },
    async postSearchData({ commit }, payload) {
      commit("postSearchData", payload);

      try {
        await postSearchDataAsync({
          keyword: payload.keyword,
          type: payload.type,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async postReachDestination() {
      try {
        const startPointEndPointInfo = this.getters.startPointEndPointInfo;
        await postReachDestinationAsync(startPointEndPointInfo);
      } catch (error) {
        console.log(error);
      }
    },
    //when user searches a location set found location
    getStoreLocation({ commit, state }, payload) {
      commit("loading", true);
      //Reset previews results from state
      // commit("drawRoute", []);
      // commit("routeInfo", []);
      // commit("startPointEndPointInfo", {});
      commit("routeFloor", 0);
      //Set store location info
      let arrIndex = 0;
      let floor = 0;
      state.floors.forEach((e) => {
        if (e.level == payload.floor) {
          floor = arrIndex;
        }
        arrIndex++;
      });

      commit("setFloor", floor);
      commit("setId", payload.Id);
      commit("setTitle", payload.title);
      if (payload.center) {
        commit("setCenter", payload.center);
      }

      const navigationZoomLevel = this.getters.coordinates[this.getters.floor]
        .properties.navigationZoomLevel
        ? this.getters.coordinates[this.getters.floor].properties
            .navigationZoomLevel
        : 19;

      //change zoom level
      commit("setZoom", navigationZoomLevel);
      commit("loading", false);
    },
    //if user comes from QR Code set its location
    defaultUserLocation({ commit }, payload) {
      commit("defaultUserLocation", payload);
    },
    changeFloor({ commit }, payload) {
      //change floor

      // commit("loading", true);
      commit("changeFloor", payload);
      //when changing a floor "found store" will be reset
      commit("setTitle", false);

      commit(
        "setCenter",
        this.getters.coordinates[payload].properties.centerPoint
      );
      commit("setZoom", this.getters.coordinates[payload].properties.zoomLevel);
      commit("loading", false);
    },
    //Changing floor to directly to other levels not one by one
    changeFloor2({ commit }, payload) {
      let b;
      let floor = 0;

      for (b = 0; b < this.state.floors.length; b++) {
        if (this.state.floors[b].level == payload) {
          floor = b;
        }
      }

      //change floor
      commit("loading", true);
      commit("changeFloor2", floor);

      //when changing a floor "found store" will be reset
      commit("setTitle", false);
      commit(
        "setCenter",
        this.getters.coordinates[this.getters.floor].properties.centerPoint
      );
      commit(
        "setZoom",
        this.getters.coordinates[this.getters.floor].properties.zoomLevel
      );
      commit("loading", false);
    },
    async getRoute({ commit }, payload) {
      commit("reset");

      if (payload.endPoint === undefined) {
        payload.endPoint = localStorage.getItem("carPositionId");
      }

      //check if user has current location
      const userLocation = this.getters.defaultUserLocation
        ? this.getters.defaultUserLocation
        : payload.startPoint;

      let startLocation = null;
      this.getters.stores.forEach((item) => {
        if (item.id === userLocation) {
          startLocation = item.location.geometry.coordinates;
        }
      });

      //Checks if user search wc, elevator or stores
      setTimeout(() => {
        commit("routerLoader", true);
      }, 1);

      let newRoute = await getRoutesAsync({
        statrtLocation: userLocation,
        endLocation: payload.endPoint,
      });

      commit("routerLoader", false);
      if (newRoute.data.route.length === 0) {
        commit("noRoutes", true);
      } else {
        commit("noRoutes", false);
      }

      let arrIndex = 0;
      let floor = 0;
      this.state.floors.forEach((e) => {
        if (e.level == payload.floor) {
          floor = arrIndex;
        }
        arrIndex++;
      });
      if (payload.center) {
        commit("setCenter", payload.center);
      }

      commit("setFloor", floor);

      let routeCoordinates = [];

      newRoute.data.route.forEach((e, i) => {
        this.getters.allData.find((e) => {
          if (e.id === newRoute.data.route[i][0]) {
            let floor = 0;

            this.state.floors.forEach((floorItem, index) => {
              if (floorItem.level == e.location.properties.floorLevel) {
                floor = index;
              }
            });

            if (
              e.filters.pointType.find((item) => item === "checkpoint") ||
              e.filters.pointType.find((item) => item === "info") ||
              (e.properties && e.properties.restrictionWarning)
            ) {
              if (e.navigation.properties.isVisibleOnList === false) {
                const selectedPlacePoint = turf.point(
                  e.location.geometry.coordinates
                );
                const searchRadius = 0.005; // radius in kilometers

                const filterdPlacesAround = [];
                this.getters.stores.forEach((store) => {
                  if (
                    Object.prototype.hasOwnProperty.call(
                      e.location.geometry,
                      "coordinates"
                    ) &&
                    store.location.geometry.coordinates
                  ) {
                    let comparePoint = turf.point(
                      store.location.geometry.coordinates
                    );
                    const distance = turf.distance(
                      selectedPlacePoint,
                      comparePoint,
                      {
                        units: "kilometers",
                      }
                    );

                    if (distance > searchRadius && distance !== 0) {
                      if (
                        store.navigation.properties.isVisibleOnList === true
                      ) {
                        filterdPlacesAround.push({
                          store: store,
                          distance: distance,
                        });
                      }
                    }
                  }
                });

                const lowestDistancePlace = filterdPlacesAround.reduce(
                  (prev, curr) => {
                    return prev.distance < curr.distance ? prev : curr;
                  },
                  filterdPlacesAround[0]?.distance
                );

                routeCoordinates.push({
                  coordinates: e.location.geometry.coordinates,
                  floor: floor,
                  direction: newRoute.data.route[i][2],
                  distance: newRoute.data.route[i][1],
                  id: e.id,
                  filters: e.filters,
                  title: lowestDistancePlace?.store?.title,
                  navigation: e.navigation,
                  restrictionWarning: e?.properties?.restrictionWarning || "",
                });
              } else {
                routeCoordinates.push({
                  coordinates: e.location.geometry.coordinates,
                  floor: floor,
                  direction: newRoute.data.route[i][2],
                  distance: newRoute.data.route[i][1],
                  id: e.id,
                  filters: e.filters,
                  title: e.title,
                  navigation: e.navigation,
                  restrictionWarning: e?.properties?.restrictionWarning || "",
                });
              }
            } else {
              routeCoordinates.push({
                coordinates: e.location.geometry.coordinates,
                floor: floor,
                direction: newRoute.data.route[i][2],
                distance: newRoute.data.route[i][1],
                id: e.id,
                filters: e.filters,
                title: e.title,
                navigation: e.navigation,
              });
            }
          }
        });
      });

      let j;

      let drawRoute = [];
      let drawRouteFloor = [];

      let prevValue = null;
      let mergedRoute = [];

      routeCoordinates.forEach((point, index) => {
        const hasFilterPoints =
          point.filters?.pointType?.includes("checkpoint") ||
          point.filters?.pointType?.includes("info") ||
          (point.restrictionWarning !== "" &&
            point.restrictionWarning !== undefined);

        if (
          !prevValue ||
          (point.direction === "s" && !hasFilterPoints) ||
          (prevValue.direction === point.direction &&
            point.direction === "s" &&
            !hasFilterPoints)
        ) {
          prevValue = {
            coordinates: prevValue ? prevValue.coordinates : point.coordinates,
            floor: prevValue ? prevValue.floor : point.floor,
            direction: prevValue ? prevValue.direction : point.direction,
            distance: prevValue
              ? point.distance + prevValue.distance
              : point.distance,
            id: prevValue ? prevValue.id : point.id,
            filters: prevValue ? prevValue.filters : point.filters,
            title: prevValue ? prevValue.title : point.title,
            restrictionWarning: prevValue?.restrictionWarning,
          };
        }

        // Merge consecutive "u" or "d" points into a single step
        else if (
          !prevValue ||
          (prevValue.direction === point.direction && point.direction === "u")
        ) {
          const nextPoint = routeCoordinates[index + 1];
          prevValue.distance += point.distance;

          // Push nextPoint only if it changes direction from "u" or "d"
          if (
            nextPoint &&
            nextPoint.direction !== "u" &&
            nextPoint.direction !== "d"
          ) {
            mergedRoute.push(nextPoint);
          }
        } else if (
          !prevValue ||
          (prevValue.direction === point.direction && point.direction === "d")
        ) {
          const nextPoint = routeCoordinates[index + 1];
          prevValue.distance += point.distance;

          // Push nextPoint only if it changes direction from "d"
          if (
            nextPoint &&
            nextPoint.direction !== "d" &&
            prevValue.direction !== "d"
          ) {
            mergedRoute.push(point);
            mergedRoute.push(nextPoint);
          } else if (
            nextPoint &&
            nextPoint.direction !== "u" &&
            nextPoint.direction !== "d"
          ) {
            mergedRoute.push(nextPoint);
          }
        }
        // If the direction changes to up ("u") or down ("d"), add the points
        else if (point.direction === "u" || point.direction === "d") {
          const nextPoint = routeCoordinates[index + 1];

          mergedRoute.push({
            coordinates: prevValue ? prevValue.coordinates : point.coordinates,
            floor: prevValue ? prevValue.floor : point.floor,
            direction: prevValue ? prevValue.direction : point.direction,
            distance: prevValue ? prevValue.distance : point.distance,
            id: prevValue ? prevValue.id : point.id,
            filters: prevValue ? prevValue.filters : point.filters,
            restrictionWarning: prevValue?.restrictionWarning,
            title: prevValue ? prevValue.title : point.title,
          });
          mergedRoute.push(point);
          if (
            nextPoint &&
            nextPoint.direction !== "u" &&
            nextPoint.direction !== "d"
          ) {
            mergedRoute.push(nextPoint);
          }

          prevValue = point;
        }
        // Handle points with specific filters or restrictions
        else if (hasFilterPoints) {
          mergedRoute.push({
            coordinates: prevValue ? prevValue.coordinates : point.coordinates,
            floor: prevValue ? prevValue.floor : point.floor,
            direction: prevValue ? prevValue.direction : point.direction,
            distance: prevValue ? prevValue.distance : point.distance,
            id: prevValue ? prevValue.id : point.id,
            filters: prevValue ? prevValue.filters : point.filters,
            restrictionWarning: prevValue?.restrictionWarning,
            title: prevValue ? prevValue.title : point.title,
          });
          prevValue = point;
        }
        // Handle other directions like left ("l"), right ("r"), slight left ("sl"), slight right ("sr")
        else if (
          point.direction === "l" ||
          point.direction === "r" ||
          point.direction === "sl" ||
          (point.direction === "sr" && !hasFilterPoints)
        ) {
          mergedRoute.push({
            coordinates: prevValue ? prevValue.coordinates : point.coordinates,
            floor: prevValue ? prevValue.floor : point.floor,
            direction: prevValue ? prevValue.direction : point.direction,
            distance: prevValue ? prevValue.distance : point.distance,
            id: prevValue ? prevValue.id : point.id,
            filters: prevValue ? prevValue.filters : point.filters,
            title: prevValue ? prevValue.title : point.title,
            restrictionWarning: prevValue?.restrictionWarning,
          });

          prevValue = point;
        }
        // Default case: add the previous value and update the current value
        else {
          mergedRoute.push(prevValue);
          prevValue = point;
        }
      });

      mergedRoute.push(routeCoordinates[routeCoordinates.length - 1]);

      const removeDuplicates = (array, key) => {
        return [...new Map(array.map((item) => [item[key], item])).values()];
      };

      const uniqueArray = removeDuplicates(mergedRoute, "id");

      if (!mergedRoute.length) {
        commit("routeInfo", []);
      } else {
        commit("routeInfo", uniqueArray);
      }

      //MapBox coordinates to draw route
      for (j = 0; j < routeCoordinates.length - 1; j++) {
        if (routeCoordinates[j].floor === routeCoordinates[j + 1].floor) {
          drawRouteFloor.push(routeCoordinates[j].coordinates);
          if (j === routeCoordinates.length - 2) {
            drawRouteFloor.push(routeCoordinates[j + 1].coordinates);
            drawRoute.push(drawRouteFloor);
          }
        } else {
          drawRouteFloor.push(routeCoordinates[j].coordinates);
          drawRoute.push(drawRouteFloor);
          drawRouteFloor = [];
        }
      }

      commit("drawRoute", drawRoute);
      commit(
        "setZoom",
        this.getters.coordinates[this.getters.floor].properties.zoomLevel
      );

      if (drawRoute.length > 0) {
        commit("startPointEndPointInfo", {
          startLocation: startLocation,
          endLocation:
            this.state.routeInfo[this.state.routeInfo.length - 1].coordinates,
          startPoint: userLocation,
          endPoint: payload.endPoint,
          eta: newRoute?.data?.eta,
          floor: payload.floor,
          center: payload.center,
        });
      }

      postRouteAnalyses({
        startPoint: userLocation,
        endPoint: this.state.routeInfo[this.state.routeInfo.length - 1].id,
        startLocation: startLocation,
        eta: newRoute?.data?.eta,
        distance: newRoute.data.dist,
        route: this.state.routeInfo,
        routeType: payload.routeType
          ? payload.routeType
          : this.getters.routeType,
      });

      commit("pureRoute", newRoute);
    },

    //when getting route directions, if floor changes
    routeFloor({ commit }, payload) {
      let i = this.getters.routeFloor + payload;

      commit("routeFloor", i);
    },
    resetRouteFloor({ commit }, payload) {
      commit("routeFloor", payload);
    },

    //when user clicks next change stepper state
    stepper({ commit }, payload) {
      var i = this.getters.stepper + payload;
      commit("stepper", i);
    },
    resetStepper({ commit }, payload) {
      commit("stepper", payload);
    },

    //reset all state
    async reset({ commit }) {
      commit("setTitle", "");
      commit("stepper", 0);
      commit("drawRoute", []);
      commit("pureRoute", []);

      commit("startPointEndPointInfo", {});
      commit("routeInfo", []);
      commit("routeFloor", 0);
      commit("shopInfo", {
        title: "",
        category: "",
        icon: "",
        properties: {
          categoryId: "",
          image: "",
          phone: "",
          subcategoryId: "",
          text: "",
          webSite: "",
          workingHours: "",
          zoneId: "",
        },
        center: "",
        isCluster: false,
      });

      commit("setCenter", this.getters.center);
      commit("setZoom", this.getters.zoom);
      //  await this.dispatch("getPlace");
    },
    setTitle({ commit }, payload) {
      commit("setTitle", payload);
    },
    resetRouteToFirst({ commit }) {
      // let temp = this.getters.drawRoute;
      // commit("drawRoute", []);
      // commit("drawRoute", temp);

      commit("setCenter", this.getters.center);
      commit("setZoom", this.getters.zoom);
    },
    zoom({ commit }, payload) {
      commit("setZoom", payload);
    },
    center({ commit }, payload) {
      commit("setCenter", payload);
    },
  },
  /////GETTERS///////
  getters: {
    placeConfig(state) {
      return state.placeConfig;
    },
    routeType(state) {
      return state.routeType;
    },
    mapRef(state) {
      return state.mapRef;
    },
    routerLoader(state) {
      return state.routerLoader;
    },
    focusCoordsParamsInfo(state) {
      return state.focusCoordsParamsInfo;
    },
    openFloorsDropDown(state) {
      return state.openFloorsDropDown;
    },
    urlParamsConfigs(state) {
      return state.urlParamsConfigs;
    },
    place(state) {
      return state.place;
    },
    stores(state) {
      return state.stores;
    },
    allData(state) {
      return state.allData;
    },
    noRoutes(state) {
      return state.noRoutes;
    },
    loading(state) {
      return state.loading;
    },
    placesLoading(state) {
      return state.placesLoading;
    },
    campaign(state) {
      return state.campaign;
    },
    categories(state) {
      return state.categories;
    },
    coordinates(state) {
      return state.coordinates;
    },
    floor(state) {
      return state.floor;
    },
    center(state) {
      return state.center;
    },
    title(state) {
      return state.title;
    },
    id(state) {
      return state.Id;
    },
    drawRoute(state) {
      return state.drawRoute;
    },
    zoom(state) {
      return state.zoom;
    },
    routeInfo(state) {
      return state.routeInfo;
    },
    startPointEndPointInfo(state) {
      return state.startPointEndPointInfo;
    },
    styleUrl(state) {
      return state.styleUrl;
    },
    accessToken(state) {
      return state.accessToken;
    },
    routeFloor(state) {
      return state.routeFloor;
    },
    stepper(state) {
      return state.stepper;
    },
    user(state) {
      return state.user;
    },
    defaultUserLocation(state) {
      return state.defaultUserLocation;
    },
    parkingLabels(state) {
      return state.parkingLabels;
    },
    parkingSpots(state) {
      return state.parkingSpots;
    },
    navigation(state) {
      return state.navigation;
    },
    clustersList(state) {
      return state.clustersList;
    },
    showClusters(state) {
      return state.showClusters;
    },
    searchDestination(state) {
      return state.searchDestination;
    },
    destinationDialog(state) {
      return state.destinationDialog;
    },
    floors(state) {
      return state.floors;
    },
    shopInfo(state) {
      return state.shopInfo;
    },
    routeWarning(state) {
      return state.routeWarning;
    },
    facilitiesTitle(state) {
      return state.facilitiesTitle;
    },
    postSearchData(state) {
      return state.postSearchData;
    },
    pureRoute(state) {
      return state.pureRoute;
    },
  },
});
